import { Component, OnInit } from '@angular/core';
import { Experience } from './Models/experience.model';
import { Overview } from './Models/overview.model';
import { Section } from './Models/section.model';
import { Skill } from './Models/skill.model';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'resume';
  ov: Overview;
  skill: Section;
  experience: Section;

  constructor(){}

  ngOnInit(): void {
    this.ov = new Overview(
      "Vincent Fisher",
      "https://www.linkedin.com/in/vincent-fisher-1368a3104/",
      "/cv",
      "ServiceNow developer from London, United Kindgom."
    );

    this.skill = new Section(
      "My Skills & Abilities",
      "With a Bsc degree in \"Physics with Computer Science\" from the University of Birmingham; I pride myself in being curious for knowledge and quick to adapt.",
      "./assets/images/skills.jpg",
      [
        new Skill("Javascript - 8+ Years Experience", 95, "#02592e"),
        new Skill("ServiceNow, Custom Application Developer - 7+ Years Experience", 90, "#0f5daa"),
        new Skill("Web services & Integrations - 7+ Years Experience", 90, "#e07702"),
        new Skill("Web frameworks, AngularJS & Angular - 6+ Years Experience", 85, "#f5e200"),
        new Skill("Html / CSS - 6+ Years Experience", 85, "#dd59ad")
      ]
    );

    this.experience = new Section(
      "My Experience",
      "Having worked primarily as a ServiceNow developer for the past few years, I have a multitude of experience with both large and small companies. This involves working as a consultant on client projects, as well as a developer directly for customers.",
      "./assets/images/exp.jpg",
      [
        new Experience("Sky", "Principal ServiceNow Developer", "Jul 2019 - Present"),
        new Experience("LadbrokesCoral Group", "Primary ServiceNow Developer", "Apr 2018 - Jun 2019"),
        new Experience("TESM", "ServiceNow Developer", "Sep 2017 - Mar 2018"),
        new Experience("Team Ultra", "Assosciate ServiceNow Developer", "Sep 2016 - Sep 2017"),
        new Experience("Virgin Media", "Graduate Engineer", "Sep 2015 - Sep 2016")
      ]
    );
  }
}
